// @flow

import { type Project } from '../types';
import * as copy from '../copy';
import sendimeterLogo from '../assets/sendimeter.logo.gif';
import coinholioLogo from '../assets/coinholio.logo.png';
import insertGifLogo from '../assets/insert.gif.logo.png';
import tododropLogo from '../assets/tododrop.logo.png';
import pairforceLogo from '../assets/pairforce.logo.png';
import dashboardImage from '../assets/dashboard.image.png';
import drumgImage from '../assets/drumg.image.png';
import vibeCheqLogo from '../assets/vibecheq.logo.png';

export const data: Array<Project> = [
  {
    name: 'VibeCheq',
    description: copy.vibeCheq,
    link: 'https://vibecheq.com/',
    logo: vibeCheqLogo,
  },
  {
    name: 'Sendimeter',
    description: copy.sendimeter,
    link: 'https://sendimeter.com/',
    logo: sendimeterLogo,
    logoBackground: '#080e31',
  },
  {
    name: 'Coinholio',
    description: copy.coinholio,
    link: 'https://coinhol.io/',
    logo: coinholioLogo,
    backgroundGradient: 'radial-gradient(circle, rgba(243,254,255,1) 32%, rgba(200,214,217,1) 100%)',
  },
  {
    name: 'InsertGif',
    description: copy.insertGif,
    link: 'https://insertgif.com/',
    logo: insertGifLogo,
    backgroundGradient: 'radial-gradient(circle, rgba(253,251,226,1) 32%, rgba(206,204,164,1) 100%)',
  },
  {
    name: 'The Originate Dashboard',
    description: copy.theOriginateDashboard,
    logo: dashboardImage,
    demoAvailable: true,
    logoBackground: '#10161a',
  },
  {
    name: 'Tododrop',
    description: copy.tododrop,
    link: 'https://tododrop.com/',
    logo: tododropLogo,
    backgroundGradient: 'radial-gradient(circle, rgba(251,255,255,1) 32%, rgba(222,228,231,1) 100%)',
  },
  {
    name: 'Pairforce',
    description: copy.pairforce,
    link: 'https://pairforce.io/',
    logo: pairforceLogo,
    logoBackground: '#0b0b0f',
  },
  {
    name: 'Drumg Prototype',
    description: copy.drumgPrototype,
    demoAvailable: true,
    logo: drumgImage,
    logoBackground: '#160b19',
  },
];
