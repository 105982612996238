// @flow

import React from 'react';
import styled from 'styled-components';

import { theme } from '../constants';
import { Water } from './water';
import { mobile } from '../constants';

const BackgroundSvg = styled.div`
  svg {
    position: absolute;
    z-index: -1;
    top: ${theme.water.topMarker}px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    ${mobile} {
      top: 440px;
    }
  }
`;

const BackgroundColor = styled.div`
  position: absolute;
  top: ${theme.water.topMarker + 100}px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: ${theme.water.backgroundColor};
  z-index: -1;
`;

export const Background = () => (
  <React.Fragment>
    <BackgroundColor />
    <BackgroundSvg>
      <Water count={5} color={theme.water.backgroundColorWithTransparency} />
    </BackgroundSvg>
  </React.Fragment>
);
