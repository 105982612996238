// @flow

export const mobile = '@media only screen and (max-width: 720px)';
export const desktop = '@media only screen and (min-width: 721px)';

export const colors = {
  blue: '#62ccf1',
  purple: '#bb8be1',
  pink: '#f166ad',
  green: '#6ce7a0',
  yellow: '#eeda5d',
};

export const theme = {
  water: {
    backgroundColor: colors.blue,
    backgroundColorWithTransparency: `${colors.blue}9F`,
    topMarker: 750,
  },
  footer: {
    padding: {
      top: 250,
      left: 0,
      right: 0,
      bottom: 150,
    },
    horizontalMargin: 70,
    h2: {
      fontSize: 40,
      margin: {
        bottom: 30,
      },
    },
    h3: {
      fontSize: 30,
      margin: {
        bottom: 3,
      },
    },
    p: {
      color: '#666',
    },
    info: {
      verticalMargin: 20,
    },
    line: {
      margin: {
        bottom: 30,
      },
    },
  },
};
