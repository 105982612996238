// @flow

import React, { Fragment as HTML } from 'react';

// =====================================================================================================================
// this page contains all the copy for this project. it is organized in a way that it is *hopefully* easy to
// edit by non-engineers who know how to make edits on github.
// =====================================================================================================================
//
// NOTE for non-engineers:
//
//   the text in-between the "<HTML>" and "</HTML>" tags is what shows up on the page. there are a few caveats.
//   the main one is that (and you'll see this below), if you have some HTML on its own
//   line, you need to put a "{' '}" at the end of the line above (if you want a space between the text and the
//   element). this is kinda silly, i know, but if you're curious about why, it's a react specific thing:
//   https://stackoverflow.com/a/41142436/410286
//
// =====================================================================================================================
// intro text that appears at the top of the page
// =====================================================================================================================
export const intro = (
  <HTML>
    At Originate, R&D is in our DNA. We love building new ideas, prototyping, and tinkering with new technologies. This
    helps keep us up to date with modern software development and let's us keep excercising our creative muscles. Below
    you'll find a selection of some of the products we have built in-house.
  </HTML>
);

// =====================================================================================================================
// project specific descriptions
// =====================================================================================================================
export const sendimeter = (
  <HTML>
    Sendimeter is a Chrome extension that gives you an unbiased opinion on the tone of your email. Avoid sounding angry
    in an email that's meant to be happy. Avoid sounding happy in an email that's meant to express concern. Sendimeter
    helps you be sure that your tone matches your intent.
  </HTML>
);

export const coinholio = (
  <HTML>A tool for tracking your cryptocurrency portfolio. Optimized for a beautiful and simple experience.</HTML>
);

export const insertGif = <HTML>An OSX app that makes it super easy to insert GIFs into any textbox system wide</HTML>;

export const vibeCheq = (
  <HTML>
    VibeCheq helps you keep a pulse on a remote group of people at scale through customized and automated check-in
    emails sent out at the cadence of your choosing.
  </HTML>
);

export const theOriginateDashboard = (
  <HTML>
    The Originate Dashboard is an internal tool we use to help keep people in the loop about what projects we have, what
    technologies they're using, and what codenames we are using for our partners. Additional features: live events from
    all of the operational SaaS we use, company-wide top-rated Slack messages, most active Google Docs, and live
    updating Hacker News.
  </HTML>
);

export const tododrop = (
  <HTML>
    We believe that TODOs do not belong in a codebase, and rather, should be moved out into our ticket management
    system. We created Tododrop as a{' '}
    <a href="https://github.com/" target="_new">
      Github
    </a>{' '}
    integration to help us manage this process.
  </HTML>
);

export const pairforce = (
  <HTML>
    Share and collaborate your file tree in an online shared browser, terminal, and code editor. Perfect for distributed
    teams working on full-stack development.
  </HTML>
);

export const drumgPrototype = (
  <HTML>
    In summer 2018 we collaborated with{' '}
    <a href="https://drumg.com/" target="_new">
      DrumgG
    </a>{' '}
    to build a visualization of the performance of open source enterprise blockchain implementations{' '}
    <a href="https://www.corda.net" target="_new">
      Corda
    </a>{' '}
    vs{' '}
    <a href="https://www.goquorum.com" target="_new">
      Quorum
    </a>
    .
  </HTML>
);
