// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';
import { shuffle } from 'lodash';

import { data } from '../data/projects';
import { Project } from './project';
import { theme } from '../constants';
import { mobile } from '../constants';

const Wrapper: StyledComponent<{}, {}, HTMLElement> = styled.main`
  margin-top: 150px;

  ul {
    background-color: ${theme.water.backgroundColor};
    padding: 100px 0px;
    padding-top: 50px;
  }

  ${mobile} {
    margin-top: 10px;
  }
`;

export const Body = () => (
  <Wrapper>
    <ul>
      {shuffle(data).map(props => (
        <Project key={props.name} {...props} />
      ))}
    </ul>
  </Wrapper>
);
