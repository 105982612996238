// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { theme } from '../constants';
import originateLogo from '../assets/originate.grey.png';
import githubLogo from '../assets/github_logo.svg';
import instagramLogo from '../assets/instagram_logo.svg';
import facebookLogo from '../assets/facebook_logo.svg';
import twitterLogo from '../assets/twitter_logo.svg';
import stinkedinLogo from '../assets/stinked_in_logo.svg';
import { Drippy } from './drippy';
import { mobile } from '../constants';

const {
  footer: { padding },
} = theme;

const Wrapper: StyledComponent<{}, {}, HTMLElement> = styled.footer`
  position: relative;
  min-height: 100px;
  padding: ${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px;
  color: black;
  text-align: left;
  overflow: hidden;

  h2 {
    margin: 0px;
    margin-bottom: ${theme.footer.h2.margin.bottom}px;
    margin-left: ${theme.footer.horizontalMargin}px;
    font-size: ${theme.footer.h2.fontSize}px;
    font-weight: bolder;
  }

  h3 {
    margin: 0px;
    margin-bottom: ${theme.footer.h3.margin.bottom}px;
    font-size: ${theme.footer.h3.fontSize}px;
    font-weight: bolder;
  }

  p {
    margin: 0px;

    a,
    a:visited,
    a:focus {
      text-decoration: none;
      color: ${theme.footer.p.color};
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${theme.footer.info.verticalMargin}px ${theme.footer.horizontalMargin}px;

  ${mobile} {
    margin: 10px;
    flex-direction: column;

    & > div {
      margin-top: 10px;
    }
  }
`;

const Line = styled.div`
  border: 0.75px solid #666;
  margin: 100px ${theme.footer.horizontalMargin}px;
  margin-bottom: ${theme.footer.line.margin.bottom}px;

  ${mobile} {
    margin: 100px 10px;
  }
`;

const BottomMatter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 0px ${theme.footer.horizontalMargin}px;

  ${mobile} {
    flex-direction: column;
    margin: 0px 0px;
    align-items: center;
  }
`;

const Left = styled.div`
  ${mobile} {
    margin-bottom: 20px;
  }
`;

const Right = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }

  a,
  div {
    text-transform: uppercase;
    text-decoration: none;
    color: #666;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;

    & + a,
    & + div {
      margin-left: 30px;
      ${mobile} {
        margin-left: 5px;
      }
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
`;

export const Footer = () => (
  <Wrapper>
    <Drippy intervalSeconds={2} color={theme.water.backgroundColor} />
    <h2>Let's Talk</h2>
    <Info>
      <div>
        <h3>Give Us a Call</h3>
        <p>(800)&nbsp;352-2292</p>
      </div>
      <div>
        <h3>Say Hello</h3>
        <p>
          <a href="mailto:hello@originate.com">hello@originate.com</a>
        </p>
      </div>
      <div>
        <h3>Business Inquiries</h3>
        <p>
          <a href="mailto:partner@originate.com">partner@originate.com</a>
        </p>
      </div>
      <div>
        <h3>Careers</h3>
        <p>
          <a href="mailto:work@originate.com">work@originate.com</a>
        </p>
      </div>
    </Info>
    <Line />
    <BottomMatter>
      <Left>
        <img alt="originate logo" src={originateLogo} />
      </Left>
      <Right>
        <a href="https://originate.com/contact">contact</a>
        <a href="https://originate.com/privacy">privacy policy</a>
        <div>&copy; 2019 originate, inc.</div>
        <a href="https://github.com/originate">
          <img src={githubLogo} alt="github" />
        </a>
        <a href="https://instagram.com/weareoriginate">
          <img src={instagramLogo} alt="instagram" />
        </a>
        <a href="https://facebook.com/weareoriginate">
          <img src={facebookLogo} alt="facebook" />
        </a>
        <a href="https://twitter.com/originatelabs">
          <img src={twitterLogo} alt="twitter" />
        </a>
        <a href="https://linkedin.com/company/originate">
          <img src={stinkedinLogo} alt="stinkedin" />
        </a>
      </Right>
    </BottomMatter>
  </Wrapper>
);
