// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';

import logo from '../assets/logo.gif';
import * as copy from '../copy';
import { mobile } from '../constants';

const Wrapper: StyledComponent<{}, {}, HTMLElement> = styled.header`
  margin: 50px;

  ${mobile} {
    margin: 0px auto;
    padding: 0px;
  }
`;

const Link = styled.a`
  color: black;
`;

const Logo = styled.img`
  width: 800px;
  min-height: 560px;
  pointer-events: none;
  position: relative;
  left: -35px;

  ${mobile} {
    width: 100%;
    min-height: auto;
    margin: 0px auto;
    position: static;
  }
`;

const Intro = styled.div`
  color: black;
  max-width: 60%;
  margin: 0px auto;
  padding: 30px;
  padding-top: 0px;
  border-radius: 10px;
  text-align: left;
  position: relative;
  z-index: 100;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5em;
  top: -20px;

  ${mobile} {
    font-size: 14px;
    margin: 5px;
    max-width: none;
  }
`;

export const Header = () => (
  <Wrapper>
    <Link href="http://originate.com" target="_blank" rel="noopener noreferrer">
      <Logo src={logo} alt="logo" />
    </Link>
    <Intro>{copy.intro}</Intro>
  </Wrapper>
);
