// @flow

import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './components/app';

import './index.css';

const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOM.render(<App />, rootElement);
} else throw new Error('me need elements');

// $FlowFixMe
if (module.hot) {
  module.hot.accept('./components/app', () => {
    const { App } = require('./components/app');
    ReactDOM.render(<App />, rootElement);
  });
}
