// @flow

import React from 'react';

import { SvgCurve } from './svg_curve';

type Props = {
  count: number,
  color: string,
};

export const Water = ({ count, color }: Props) => (
  <React.Fragment>
    {new Array(count).fill(0).map((_, i) => (
      <SvgCurve
        key={i}
        width={500}
        height={200}
        color={color}
        backgroundColor="transparent"
        jitter={(i + 1) * 2}
        secondsToAnimate={(i + 1) / 10}
        pointCount={(count - i) * 5}
      />
    ))}
  </React.Fragment>
);
