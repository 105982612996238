// @flow

import React from 'react';

import { generateSVGPath, type Curve } from '../utils/curve';

const randomPoints = (count: number, max: number, jitter: number): Curve => {
  return new Array(count + 1).fill(0).map((_, i) => [(i / count) * max, Math.random() * jitter - jitter / 2 + 10]);
};

type Props = {
  width: number,
  height: number,
  jitter: number,
  pointCount: number,
  secondsToAnimate: number,
  color: string,
  backgroundColor: string,
};

type State = {
  lastTimeMarker: number,
  nextPoints: Curve,
  previousPoints: Curve,
  currentPoints: Curve,
};

class SvgCurve extends React.Component<Props, State> {
  pathRef: Object;
  animate: number => void;

  constructor(props: Props) {
    super(props);
    this.pathRef = React.createRef();

    const previousPoints = randomPoints(props.pointCount, props.width, props.jitter);

    this.state = {
      lastTimeMarker: 0,
      nextPoints: randomPoints(props.pointCount, props.width, props.jitter),
      previousPoints,
      currentPoints: previousPoints,
    };

    this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.animate);
  }

  animate(time: number) {
    let lastTimeMarker = this.state.lastTimeMarker;
    const percent = (time - lastTimeMarker) / (this.props.secondsToAnimate * 1000);
    let previousPoints = this.state.previousPoints;
    let nextPoints = this.state.nextPoints;

    const easedPercent = false ? percent : Math.sin((percent * Math.PI) / 2);

    const currentPoints = previousPoints.map(([x, y], i) => [
      x,
      y * (1 - easedPercent) + nextPoints[i][1] * easedPercent,
    ]);

    if (percent >= 1) {
      previousPoints = nextPoints;
      nextPoints = randomPoints(this.props.pointCount, this.props.width, this.props.jitter);
      lastTimeMarker = time;
    }

    this.setState(
      {
        previousPoints,
        nextPoints,
        currentPoints,
        lastTimeMarker,
      },
      () => requestAnimationFrame(this.animate)
    );
  }

  render() {
    const { width, height, color, backgroundColor } = this.props;

    return (
      <svg
        viewBox={`0 0 ${width} ${height}`}
        style={{ backgroundColor }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={this.pathRef}
          fill={color}
          stroke="none"
          d={generateSVGPath(this.state.currentPoints, width, height)}
        />
      </svg>
    );
  }
}

export { SvgCurve };
