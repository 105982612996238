// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { Header } from './header';
import { Body } from './body';
import { Footer } from './footer';
import { Background } from './background';

const Wrapper: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  text-align: center;
  min-height: 100vh;
  color: white;

  a,
  a:visited,
  a:focus,
  a:-webkit-any-link {
    color: black;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function App() {
  return (
    <Wrapper>
      <Background />
      <Header />
      <Body />
      <Footer />
    </Wrapper>
  );
}

export { App };
