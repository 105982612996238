// @flow

type Point = [number, number];

export type Curve = Array<Point>;

type LineData = {|
  length: number,
  angle: number,
|};

const smoothingRatio = 0.2;

const line = (pointA: Point, pointB: Point): LineData => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];

  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (current: Point, previous: Point, next: Point, reverse: ?boolean): Point => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;

  // Properties of the opposed-line
  const o = line(p, n);

  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothingRatio;

  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;

  return [x, y];
};

const bezier = (point: Point, i: number, a: Curve): string => {
  const startControlPoint = controlPoint(a[i - 1], a[i - 2], point);
  const endControlPoint = controlPoint(point, a[i - 1], a[i + 1], true);

  return `C ${startControlPoint[0]},${startControlPoint[1]} ${endControlPoint[0]},${endControlPoint[1]} ${point[0]},${
    point[1]
  }`;
};

export const generateSVGPath = (points: Curve, w: number, h: number) => {
  const d = points.reduce(
    (acc, point, i, a) => (i === 0 ? `M 0,${h} L ${point[0]},${point[1]}` : `${acc} ${bezier(point, i, a)}`),
    ''
  );

  return `${d} L ${w},${h} Z`;
};
