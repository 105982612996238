// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';

import type { Project as ProjectType } from '../types';
import seeMoreUrl from '../assets/see.more.gif';
import requestDemoUrl from '../assets/request.demo.gif';
import { mobile } from '../constants';

const Wrapper: StyledComponent<{}, {}, HTMLLIElement> = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  width: 60%;
  min-height: 200px;
  margin: 0px auto;
  text-align: left;
  color: black;

  & + & {
    margin-top: 200px;
  }

  ${mobile} {
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    & + & {
      margin-top: 120px;
    }
  }
`;

const CallToAction = styled.img`
  position: absolute;
  bottom: -70px;
  right: 5%;
  height: 100px;
`;

const Info = styled.div`
  background-color: white;
  position: relative;
  flex-grow: 1;
  width: 340px;

  display: flex;
  align-items: center;
`;

const InfoBackground = styled.div`
  position: absolute;
  top: -20px;
  left: -40px;
  right: -20px;
  bottom: -20px;

  border-radius: 10px;
  background-color: white;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  ${mobile} {
    display: none;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  padding-left: 30px;
`;

const Name = styled.h3`
  font-weight: bolder;
  font-size: 26px;
  margin: 0px;
  margin-bottom: 10px;
  font-style: italic;

  ${mobile} {
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-weight: lighter;
  line-height: 22px;

  ${mobile} {
    font-size: 12px;
    font-weight: normal;
  }
`;

const TeaserImage = styled.div`
  border-radius: 10px;
  width: 300px;
  position: relative;
  z-index: 2;

  ${props =>
    props.background
      ? `background: url(${props.background}) no-repeat center center ${
          props.backgroundGradient ? `, ${props.backgroundGradient}` : ''
        };`
      : ''}
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'black')};
  background-size: contain;

  ${mobile} {
    width: 200px;
    border-radius: 0px;
  }
`;

type Props = ProjectType;

export const Project = ({
  demoAvailable,
  name,
  logo,
  description,
  link,
  logoBackground,
  backgroundGradient,
}: Props) => {
  return (
    <Wrapper>
      <TeaserImage background={logo} backgroundColor={logoBackground} backgroundGradient={backgroundGradient} />
      <Info>
        <InfoBackground />
        <Content>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </Content>
        {link && (
          <a href={link} target="_new">
            <CallToAction src={seeMoreUrl} />
          </a>
        )}
        {demoAvailable && (
          <a href="mailto:matt.handler@originate.com" target="_new">
            <CallToAction src={requestDemoUrl} />
          </a>
        )}
      </Info>
    </Wrapper>
  );
};
