// @flow

import React from 'react';
import styled, { keyframes, type StyledComponent } from 'styled-components';

const droplet = keyframes`
  0% {
      transform: translate(0, 0);
  }
  50% {
      height: 30px;
      width: 30px;
  }
  100% {
      transform: translate(0, 700px);
  }
`;

const Wrapper: StyledComponent<{ color: string }, {}, HTMLDivElement> = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  background-color: ${props => props.color};
  z-index: -1;
`;

const DripWrapper = styled.div`
  position: absolute;
  background: ${props => props.color};
  width: 250px;
  height: 25%;
  bottom: 2px;
  left: ${Math.random() * 60 + 5}%;

  -webkit-filter: url('#goo');
  filter: url('#goo');
`;

const DrippyDiv = styled.div`
  position: absolute;
  background: ${props => props.color};
  border-radius: 50%;
`;

const Drop = styled(DrippyDiv)`
  top: 0;
  left: 75%;
  width: 30px;
  height: 80px;
`;

const Droplet = styled(DrippyDiv)`
  top: 0;
  left: 64%;
  width: 20px;
  height: 55px;
`;

const Droplet2 = styled(DrippyDiv)`
  top: 20px;
  left: 76%;
  width: 20px;
  height: 35px;
  z-index: 3;
  transition: all 0.4s ease;

  &.dripit {
    animation: ${droplet} 1s 0.6s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1;
  }
`;

const SVGFilter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="0">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
        <feComposite in="SourceGraphic" in2="goo" operator="atop" />
      </filter>
    </defs>
  </svg>
);

type Props = {
  color: string,
  intervalSeconds: number,
};

type State = { dripit: boolean };

class Drippy extends React.Component<Props, State> {
  static defaultProps: {
    intervalSeconds: number,
  };

  intervalID: ?IntervalID;

  constructor(props: Props) {
    super(props);

    this.state = { dripit: true };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.setState({ dripit: true }, () => {
        setTimeout(() => {
          this.setState({ dripit: false });
        }, 10);
      });
    }, this.props.intervalSeconds * 1000);
  }

  componentWillUnmount() {
    this.intervalID && clearInterval(this.intervalID);
  }

  render() {
    return (
      <Wrapper color={this.props.color}>
        <SVGFilter />
        <DripWrapper color={this.props.color}>
          <Droplet color={this.props.color} />
          <Droplet2 color={this.props.color} className={this.state.dripit ? [] : ['dripit']} />
          <Drop color={this.props.color} />
        </DripWrapper>
      </Wrapper>
    );
  }
}

Drippy.defaultProps = {
  intervalSeconds: 4,
};

export { Drippy };
